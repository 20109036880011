<template>
  <div class="chart-info w-full relative">
    <vx-card v-if="!isLoading">
      <div class="flex flex-row items-center">
        <div class="relative w-full">
          <vs-list-header :title="componentTitle" class="card-title-font-size" color="primary"></vs-list-header>
        </div>
        <div class="ml-2 vs-list--header">
          <vx-tooltip style="width: 17px" :text="$t('info.dashboard.requestsByTypeInfo')" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>
      <!-- CHART -->
      <div>
        <vue-apex-charts
          type="donut"
          height="218"
          class="mb-12 mt-4"
          :options="campaignRequestsByType.requestsByTypeDonut.chartOptions"
          :series="campaignRequestsByType.requests.series"
        />
      </div>
      <!-- CHART DATA -->
      <ul>
        <li v-for="requestsData in campaignRequestsByType.requests.analyticsData" :key="requestsData.type" class="flex mb-3">
          <feather-icon :icon="requestsData.icon" :svgClasses="[`h-5 w-5 stroke-current`]" :style="{ color: requestsData.color }"></feather-icon>
          <span class="ml-2 inline-block font-semibold">{{ requestsData.type }}</span>
          <span class="mx-2">-</span>
          <span class="mr-4">{{ requestsData.percent }}%</span>
          <div class="ml-auto flex -mr-1">
            <span class="ml-10">{{ requestsData.total }}</span>
          </div>
        </li>
      </ul>
    </vx-card>
    <placeholder-text-small v-else />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { analyticsData } from './analyticsData'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  mixins: [analyticsData],
  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },

  components: {
    VueApexCharts,
    PlaceholderTextSmall
  },

  props: {
    title: {
      type: String,
      default: 'Item Title'
    },
    isLoading: {
      type: Boolean
    }
  },

  computed: {
    componentTitle() {
      return `${this.$i18n.t(this.title)} (${this.$i18n.t('routes.campaigns')})`
    }
  }
}
</script>
<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}
</style>
<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}
</style>
