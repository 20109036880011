<template>
  <div>
    <img :src="require(`@/assets/images/vega/feedback-${type}.svg`)" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    }
  }
}
</script>
