var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", [
        _c("div", { staticClass: "data-block-heading" }, [
          _c(
            "h3",
            {
              staticClass: "data-block-heading__button",
              on: {
                click: function ($event) {
                  return _vm.$serverBus.$emit("fetch-data-in-blocks", {
                    query: "bq-communication-analytics",
                  })
                },
              },
            },
            [
              _c("DownArrowIcon", {
                style:
                  "transform: rotate(" +
                  (_vm.isCollapsedCommunicationAnalyticsBlock
                    ? "0deg"
                    : "180deg") +
                  "); margin-right: 12px;",
                attrs: { width: 14, height: 14 },
              }),
              _vm._v("\n        Communication Analytics\n      "),
            ],
            1
          ),
        ]),
      ]),
      !_vm.isCollapsedCommunicationAnalyticsBlock
        ? [
            _vm.isVegaActivated
              ? _c(
                  "div",
                  {
                    staticClass:
                      "w-full analytics-container vega-analytics-container-blocks",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-full analytics-container--left-grow1" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex ml-0 margin-rt" },
                          [
                            _c("VegaConversationStatus", {
                              attrs: {
                                isLoading:
                                  _vm.isVegaVisitorAnalyticsDataLoading,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex margin-lt margin-rt" },
                          [
                            _c("VegaFilledContactFormStatus", {
                              attrs: {
                                isLoading:
                                  _vm.isVegaFilledContactFormStatusDataLoading,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex margin-lt" },
                          [
                            _c("VegaAgentConversationRating", {
                              attrs: {
                                isLoading:
                                  _vm.isVegaAgentConversationRatingDataLoading,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "analytics-container",
                class: { "analytics-container--mobile": _vm.isMobile },
              },
              [
                _c("div", { staticClass: "analytics-container--left" }, [
                  _c(
                    "div",
                    { staticClass: "analytics-container--left-grow1" },
                    [
                      _c(
                        "div",
                        { staticClass: "margin-rt" },
                        [
                          _c("requests-by-type", {
                            attrs: {
                              isLoading: _vm.isRequestsLoading,
                              isMobile: _vm.isMobile,
                              title: "views.home.requestsByType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "margin-lt" },
                        [
                          _c("sessions-by-type", {
                            attrs: {
                              isLoading: _vm.isSessionsLoading,
                              isMobile: _vm.isMobile,
                              title: "views.home.sessionsByType",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                          expression: "HAS_ADVANCED_DASHBOARD_ACCESS",
                        },
                      ],
                      staticClass: "analytics-container--left-grow1",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "margin-rt" },
                        [
                          _c("general-info-item", {
                            attrs: {
                              title: "views.home.clickrate",
                              value: _vm.clickrate,
                              isLoading: _vm.isClickRateLoading,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "margin-lt" },
                        [
                          _c("general-info-item", {
                            attrs: {
                              title: "views.home.sessionDuration",
                              value: _vm.sessionDuration,
                              isLoading: _vm.isSessionDurationLoading,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                          expression: "HAS_ADVANCED_DASHBOARD_ACCESS",
                        },
                      ],
                      staticClass: "analytics-container--left-grow1",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "margin-rt" },
                        [
                          _c("general-info-item", {
                            attrs: {
                              title: "views.home.shownContactForm",
                              value: _vm.shownContactForm,
                              isLoading: _vm.isContactFormLoading,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "margin-rt margin-lt" },
                        [
                          _c("general-info-item", {
                            attrs: {
                              title: "views.home.filledContactForm",
                              value: _vm.filledContactForm,
                              isLoading: _vm.isContactFormLoading,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "margin-lt" },
                        [
                          _c("general-info-item", {
                            attrs: {
                              title: "views.home.bookedAppointment",
                              value: _vm.bookedContactForm,
                              isLoading: _vm.isContactFormLoading,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  !_vm.isMobile
                    ? _c(
                        "div",
                        { staticClass: "analytics-container--left-grow1" },
                        [
                          _c("end-screen-feedback", {
                            attrs: {
                              isNewEndScreenFeedbackView: false,
                              value: _vm.endScreenFeedbackData,
                              isLoading: _vm.isEndScreenFeedbackDataLoading,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                          expression: "HAS_ADVANCED_DASHBOARD_ACCESS",
                        },
                      ],
                    },
                    [
                      _c("visitor-locations", {
                        staticClass:
                          "analytics-container--right--visitors-height",
                        attrs: {
                          topVisitors: _vm.topVisitors,
                          isVisitorsLocationLoading:
                            _vm.isVisitorsLocationLoading,
                          isMobile: _vm.isMobile,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "analytics-container--right" }, [
                  _c(
                    "div",
                    { staticClass: "analytics-container--right-grow1" },
                    [
                      _vm.routeClicks && _vm.routeClicks.length > 0
                        ? _c(
                            "div",
                            {
                              class: {
                                "ml-0":
                                  _vm.isMobile &&
                                  !_vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                              },
                            },
                            [
                              _c("route-clicks", {
                                staticClass:
                                  "analytics-container--right--visitors-height",
                                attrs: {
                                  routeClicks: _vm.routeClicks,
                                  isRouteClicksLoading:
                                    _vm.isRouteClicksLoading,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  !_vm.isMobile
                    ? _c(
                        "div",
                        { staticClass: "analytics-container--right-grow1" },
                        [
                          _c(
                            "div",
                            { staticClass: "margin-rt" },
                            [
                              _c("campaign-requests-by-type", {
                                attrs: {
                                  isLoading: _vm.isCampaignRequestsLoading,
                                  title: "views.home.requestsByType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "margin-lt" },
                            [
                              _c("campaign-sessions-by-type", {
                                attrs: {
                                  isLoading: _vm.isCampaignSessionsLoading,
                                  title: "views.home.sessionsByType",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isMobile
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                              expression: "HAS_ADVANCED_DASHBOARD_ACCESS",
                            },
                          ],
                          staticClass: "analytics-container--right-grow1",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("device-name", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.deviceData.length,
                                    expression: "deviceData.length",
                                  },
                                ],
                                staticClass:
                                  "analytics-container--right--visitors-height",
                                attrs: {
                                  deviceData: _vm.deviceData,
                                  isDeviceLoading: _vm.isDeviceLoading,
                                  title: _vm.$t("views.home.requestsByDevice"),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isMobile
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                              expression: "HAS_ADVANCED_DASHBOARD_ACCESS",
                            },
                          ],
                          staticClass: "analytics-container--right-grow1",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("table-view", {
                                attrs: {
                                  tableData: _vm.browserData,
                                  tableConfig: _vm.browserDataTableConfig,
                                  isLoading: _vm.isBrowserDataLoading,
                                  measuredBy: "sessions",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isMobile
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                              expression: "HAS_ADVANCED_DASHBOARD_ACCESS",
                            },
                          ],
                          staticClass: "analytics-container--right-grow1",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("table-view", {
                                staticClass:
                                  "analytics-container--word-break-table",
                                attrs: {
                                  tableData: _vm.topOrigins,
                                  isLoading: _vm.isOriginDataLoading,
                                  tableConfig: _vm.topOriginsTableConfig,
                                  measuredBy: "requests",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isMobile
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                              expression: "HAS_ADVANCED_DASHBOARD_ACCESS",
                            },
                          ],
                          staticClass: "analytics-container--right-grow1",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("table-view", {
                                staticClass:
                                  "analytics-container--word-break-table",
                                attrs: {
                                  tableData: _vm.topReferers,
                                  isLoading: _vm.isRefererDataLoading,
                                  tableConfig: _vm.topReferersTableConfig,
                                  measuredBy: "requests",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isMobile
                    ? _c(
                        "div",
                        { staticClass: "analytics-container--right-grow1" },
                        [
                          _c("end-screen-feedback", {
                            attrs: {
                              isNewEndScreenFeedbackView: true,
                              value: _vm.endScreenFeedbackData,
                              isLoading: _vm.isEndScreenFeedbackDataLoading,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class: {
                        "ml-0":
                          _vm.isMobile && !_vm.HAS_ADVANCED_DASHBOARD_ACCESS,
                        "analytics-container--right-grow1": true,
                      },
                    },
                    [
                      _c("agents", {
                        staticClass:
                          "analytics-container--right--visitors-height",
                        attrs: {
                          agents: _vm.agents,
                          isAgentsLoading: _vm.isAgentsLoading,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }