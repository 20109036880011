<template>
  <div class="chart-info w-full relative">
    <vx-card v-if="!isLoading">
      <div class="flex flex-row items-center">
        <div class="relative w-full">
          <vs-list-header :title="componentTitle" class="card-title-font-size" color="primary"></vs-list-header>
        </div>
        <div class="ml-2 vs-list--header">
          <vx-tooltip style="width: 17px" :text="$t('info.dashboard.sessionsByTypeInfo')" position="top">
            <img width="17" height="17" :src="infoImg" />
          </vx-tooltip>
        </div>
      </div>
      <!-- CHART -->
      <div v-if="!isMobile">
        <vue-apex-charts
          type="donut"
          height="218"
          class="mb-12 mt-4"
          :options="sessionsByType.sessionsByTypeDonut.chartOptions"
          :series="sessionsByType.sessions.series"
        />
      </div>

      <!-- CHART DATA -->
      <ul>
        <li v-for="sessionsData in sessionsByType.sessions.analyticsData" :key="sessionsData.type" class="flex mb-3">
          <feather-icon :icon="sessionsData.icon" :svgClasses="[`h-5 w-5 stroke-current`]" :style="{ color: sessionsData.color }"></feather-icon>
          <span class="ml-2 inline-block font-semibold">{{ sessionsData.type }}</span>
          <span class="mx-2">-</span>
          <span class="mr-4">{{ sessionsData.percent }}%</span>
          <div class="ml-auto flex -mr-1">
            <span class="ml-10">{{ sessionsData.total }}</span>
          </div>
        </li>
      </ul>
    </vx-card>
    <placeholder-text-small v-else />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { analyticsData } from './analyticsData'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  mixins: [analyticsData],

  components: {
    VueApexCharts,
    PlaceholderTextSmall
  },

  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg')
    }
  },

  props: {
    title: {
      type: String,
      default: 'Item Title'
    },
    isLoading: {
      type: Boolean
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    componentTitle() {
      return `${this.$i18n.t(this.title)} (${this.$i18n.t('routes.dialog')})`
    }
  }
}
</script>
<style lang="scss">
.apexcharts-datalabel-label {
  font-weight: 600 !important;
  font-family: 'Lato' !important;
}
</style>
<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}
</style>
