var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-info w-full relative" },
    [
      !_vm.isLoading
        ? _c("vx-card", [
            _c("div", { staticClass: "flex flex-row items-start" }, [
              _c(
                "div",
                { staticClass: "relative w-full" },
                [
                  _c("vs-list-header", {
                    staticClass: "card-title-font-size",
                    attrs: {
                      title: _vm.$t("views.home.conversationStatus"),
                      color: "primary",
                    },
                  }),
                  _c("div", { staticClass: "chart-info__subtitle" }, [
                    _vm._v(
                      _vm._s(_vm.$t("views.home.conversationStatusSubtitle"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-2 vs-list--header" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      staticStyle: { width: "17px" },
                      attrs: {
                        text: _vm.$t("info.dashboard.conversationStatusInfo"),
                        position: "top",
                      },
                    },
                    [
                      _c("img", {
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "mt-5 pt-4" },
              [
                _c("vue-apex-charts", {
                  attrs: {
                    type: "donut",
                    height: "230",
                    options: _vm.chartOptions,
                    series: _vm.series,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "visitor-data-list" },
              _vm._l(_vm.conversationStatusData, function (item) {
                return _c(
                  "div",
                  { key: item.name, staticClass: "visitor-data-list__item" },
                  [
                    _c("VegaIcon", { attrs: { type: item.type, width: 21 } }),
                    _c("div", [
                      _c(
                        "span",
                        { staticClass: "visitor-data-list__item__title" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _vm._v("\n          -\n          "),
                      _c(
                        "span",
                        { staticClass: "visitor-data-list__item__percent" },
                        [_vm._v(" " + _vm._s(item.percent) + "%")]
                      ),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _c("placeholder-text-small"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }